import React from "react"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import typographyStyle from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.jsx"

const Introduction = ({ classes }) => (
  <div id="team">
    <div
      className={classes.center}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <h2>About us</h2>
    </div>
    <br />
    <GridContainer
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <GridItem sm={6}>
        <h4 style={{ color: "#123E78" }}>
          West Coast Beach Volleyball Club was born from a love of being
          outdoors, getting active and of course… beach volleyball. Individually
          we have been playing volleyball in Scotland for years and have come
          together to create a base in one of Scotlands most beautiful areas.
          You can find us around Troon, Irvine and Ayr through the summer months
          and we believe that there really is no better way to spend a Saturday
          or a summer night. Volleyball is for everyone - all levels and all
          ages. So rain or shine, whether you want to compete or learn something
          new we want you to get involved.
        </h4>
      </GridItem>
    </GridContainer>
    <GridContainer />
  </div>
)

export default withStyles(typographyStyle)(Introduction)
